/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #164777;
  --ion-color-primary-rgb: 22,71,119;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #133e69;
  --ion-color-primary-tint: #2d5985;
  --ion-color-secondary: #FFC107;
  --ion-color-secondary-rgb: 255,193,7;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #fcaf17;
  --ion-color-secondary-tint: #fcaf17;
  --ion-color-tertiary: #182853;
  --ion-color-tertiary-rgb: 24,40,83;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #152349;
  --ion-color-tertiary-tint: #2f3e64;
  /** success **/
  --ion-color-success: #316220;
  --ion-color-success-rgb: 49, 98, 32;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #316220;
  --ion-color-success-tint: #316220;
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  /** danger **/
  --ion-color-danger: #a92120;
  --ion-color-danger-rgb: 169, 33, 32;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #a92120;
  --ion-color-danger-tint: #a92120;
  /** dark **/
  --ion-color-dark: #141313;
  --ion-color-dark-rgb: 20, 19, 19;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #141313;
  --ion-color-dark-tint: #141313;
  /** medium **/
  --ion-color-medium: #c6c6c6;
  --ion-color-medium-rgb: 198, 198, 198;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #c6c6c6;
  --ion-color-medium-tint: #c6c6c6;
  /** light **/
  --ion-color-light: #f5f5f5;
  --ion-color-light-rgb: 245, 245, 245;
  --ion-color-light-contrast: var(--ion-color-primary);
  --ion-color-light-contrast-rgb: var(--ion-color-primary-rgb);
  --ion-color-light-shade: #f5f5f5;
  --ion-color-light-tint: #f5f5f5;
}