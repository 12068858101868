/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
@import "./theme/modal.scss";

html {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

ion-alert {
  .alert-button-group {
    direction: rtl;
    justify-content: flex-start !important;
  }
}

ion-icon,
a {
  cursor: pointer;
}

ion-header {
  background-color: var(--ion-color-primary);
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  box-shadow: 0 0 0 transparent !important;

  ion-toolbar {
    --background: var(--ion-color-primary) url("assets/imgs/header-bg.png") no-repeat center right 95px;
    padding-top: 0 !important;
    --color: var(--ion-color-primary-contrast);
    --min-height: 65px;

    ion-buttons {
      &[slot="start"] {
        margin-right: -10px;
      }

      ion-button {
        .sinaf-icons {
          background-color: #ffffff;
        }
      }
    }

    ion-title {
      font-size: 16px;
      font-weight: 800;
      font-family: "Roboto", "Helvetica", Arial, sans-serif;
      line-height: 20px;
    }
  }
}

hr {
  background: var(--ion-color-light);
}

// Typography

.title-24 {
  font-size: 24px;
  line-height: 32px;
}

.title-20 {
  font-size: 20px;
  line-height: 24px;
}
.title-18 {
  font-size: 18px;
  line-height: 24px;
}
.text-body {
  font-size: 16px;
  line-height: 20px;
}
.text-caption {
  font-size: 14px;
  line-height: 16px;
}

.text-semi-bold {
  font-weight: 600;
}
.text-bold {
  font-weight: 800;
}
.text-capital {
  text-transform: capitalize !important;
}

.title {
  border-left: 4px solid var(--ion-color-secondary);
  padding-left: 8px;
  color: var(--ion-color-primary);
  font-weight: 800;
  &.no-border-left {
    border-left: none;
    padding-left: 0px;
  }
}

.text-success {
  color: var(--ion-color-success);
}
.text-danger {
  color: var(--ion-color-danger);
}
.text-primary {
  color: var(--ion-color-primary);
}
.text-secondary {
  color: var(--ion-color-secondary);
}

// Colors

.bg-primary {
  background-color: var(--ion-color-primary) !important;
}
.bg-secondary {
  background-color: var(--ion-color-secondary) !important;
}
.bg-success {
  background-color: var(--ion-color-success) !important;
}
.bg-danger {
  background-color: var(--ion-color-danger) !important;
}
.bg-warning {
  background-color: var(--ion-color-warning) !important;
}
.bg-dark {
  background-color: var(--ion-color-dark) !important;
}
.bg-medium {
  background-color: var(--ion-color-medium) !important;
}
.bg-light {
  background-color: var(--ion-color-light) !important;
}

// Inputs

input[type="password"] {
  font-size: 32px;
}

.form-control:focus {
  outline: none;
}

.form-control {
  display: block;
  border-radius: 8px;
  background: #ffffff !important;
  border: 1.5px solid #e6e6e6;
  padding-left: 16px;
  height: 48px;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 16px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}

.control-label {
  font-size: 14px;
  line-height: 16px;
}

ion-select::part(icon) {
  content: url("assets/imgs/icons/chevrons/down.svg");
  margin-right: 15px;
}

ion-searchbar {
  ion-icon {
    &.searchbar-search-icon,
    &.searchbar-clear-icon {
      color: var(--ion-color-primary) !important;
      left: 90% !important;
    }
  }

  input.searchbar-input:not(:placeholder-shown) + ion-icon.searchbar-search-icon {
    display: none !important;
  }

  input.searchbar-input {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    background-color: #fefefe !important;
    border: 1.5px solid #e6e6e6 !important;
    border-radius: 4px !important;
    color: #4e4e4e !important;
    padding-left: 16px !important;
    --placeholder-opacity: 1;
    --box-shadow: none;
    min-height: 48px;
  }
}

// Buttons

.btn {
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;

  &.btn-large {
    height: 64px;
    display: block;
    width: 100%;
    font-weight: 800;
  }

  .sinaf-icons {
    display: inline-block;
    margin-right: 12px;
  }
  &.btn-light {
    background: var(--ion-color-light);
    color: var(--ion-color-light-contrast);

    .sinaf-icons {
      background-color: var(--ion-color-light-contrast);
    }
  }
  &.btn-primary {
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);

    .sinaf-icons {
      background-color: var(--ion-color-primary-contrast);
    }

    &:disabled {
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
    }
  }
  &.btn-secondary {
    background: var(--ion-color-secondary);
    color: var(--ion-color-secondary-contrast);

    .sinaf-icons {
      background-color: var(--ion-color-secondary-contrast);
    }
  }
  &.btn-success {
    background: var(--ion-color-success);
    color: var(--ion-color-success-contrast);

    .sinaf-icons {
      background-color: var(--ion-color-success-contrast);
    }
  }
  &.btn-danger {
    background: var(--ion-color-danger);
    color: var(--ion-color-danger-contrast);

    .sinaf-icons {
      background-color: var(--ion-color-danger-contrast);
    }
  }
}

// Icons

.sinaf-icons {
  width: 16px;
  height: 16px;
  display: inline-block;

  &.chevrons {
    background-color: #fff;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-size: contain;

    &.chevron-left {
      mask-image: url("assets/imgs/icons/chevrons/left.svg");
      -webkit-mask-image: url("assets/imgs/icons/chevrons/left.svg");
    }
    &.chevron-right {
      mask-image: url("assets/imgs/icons/chevrons/right.svg");
      -webkit-mask-image: url("assets/imgs/icons/chevrons/right.svg");
    }
    &.chevron-up {
      mask-image: url("assets/imgs/icons/chevrons/up.svg");
      -webkit-mask-image: url("assets/imgs/icons/chevrons/up.svg");
    }
    &.chevron-down {
      mask-image: url("assets/imgs/icons/chevrons/down.svg");
      -webkit-mask-image: url("assets/imgs/icons/chevrons/down.svg");
    }
  }

  &.general {
    background-color: #fff;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-size: contain;

    &.whatsapp {
      mask-image: url("assets/imgs/icons/general/whatsapp.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/whatsapp.svg");
    }
    &.add_circle {
      mask-image: url("assets/imgs/icons/general/add_circle.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/add_circle.svg");
    }
    &.call {
      mask-image: url("assets/imgs/icons/general/call.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/call.svg");
    }
    &.close {
      mask-image: url("assets/imgs/icons/general/close.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/close.svg");
    }
    &.delete {
      mask-image: url("assets/imgs/icons/general/delete.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/delete.svg");
    }
    &.diversity_4 {
      mask-image: url("assets/imgs/icons/general/diversity_4.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/diversity_4.svg");
    }
    &.door_open {
      mask-image: url("assets/imgs/icons/general/door_open.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/door_open.svg");
    }
    &.edit {
      mask-image: url("assets/imgs/icons/general/edit.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/edit.svg");
    }
    &.error {
      mask-image: url("assets/imgs/icons/general/error.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/error.svg");
    }
    &.help {
      mask-image: url("assets/imgs/icons/general/help.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/help.svg");
    }
    &.key {
      width: 22px;
      mask-image: url("assets/imgs/icons/general/key.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/key.svg");
    }
    &.location_on {
      mask-image: url("assets/imgs/icons/general/location_on.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/location_on.svg");
    }
    &.location_searching {
      mask-image: url("assets/imgs/icons/general/location_searching.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/location_searching.svg");
    }
    &.logout {
      mask-image: url("assets/imgs/icons/general/logout.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/logout.svg");
    }
    &.mail {
      mask-image: url("assets/imgs/icons/general/mail.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/mail.svg");
    }
    &.mark_email_read {
      mask-image: url("assets/imgs/icons/general/mark_email_read.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/mark_email_read.svg");
    }
    &.person {
      mask-image: url("assets/imgs/icons/general/person.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/person.svg");
    }
    &.search {
      mask-image: url("assets/imgs/icons/general/search.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/search.svg");
    }
    &.sms_failed {
      mask-image: url("assets/imgs/icons/general/sms_failed.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/sms_failed.svg");
    }
    &.task_alt {
      mask-image: url("assets/imgs/icons/general/task_alt.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/task_alt.svg");
    }
    &.visibility {
      mask-image: url("assets/imgs/icons/general/visibility.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/visibility.svg");
    }
    &.carteirinha {
      height: 18px;
      mask-image: url("assets/imgs/icons/tab-bar/active/carteirinha.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/carteirinha.svg");
    }
    &.medicamento {
      mask-image: url("assets/imgs/icons/general/medicamento.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/medicamento.svg");
    }
    &.sacola-compras {
      mask-image: url("assets/imgs/icons/general/sacola-compras.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/sacola-compras.svg");
    }
    &.farmacia {
      mask-image: url("assets/imgs/icons/general/farmacia.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/farmacia.svg");
    }
    &.receita {
      mask-image: url("assets/imgs/icons/general/receita.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/receita.svg");
    }
    &.build {
      mask-image: url("assets/imgs/icons/general/build-outline.svg");
      -webkit-mask-image: url("assets/imgs/icons/general/build-outline.svg");
    }
  }
}

.text-primary .sinaf-icons {
  background-color: var(--ion-color-primary) !important;
}
.text-secondary .sinaf-icons {
  background-color: var(--ion-color-secondary) !important;
}

// Tab Bar

:root {
  --app-tab-bar-height: 76px;
}

ion-tab-bar {
  height: var(--app-tab-bar-height);
  --height: var(--app-tab-bar-height);
  --background: var(--ion-color-light) !important;

  ion-tab-button {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    .sinaf-icons {
      margin-bottom: 7px;
    }
  }
}

ion-tab-button.tab-selected {
  border-top: 3px solid var(--ion-color-primary);
  font-weight: 800;

  .sinaf-icons {
    &.tab-bar {
      &.home {
        background-image: url("assets/imgs/icons/tab-bar/active/home.svg");
      }
      &.carteirinha {
        background-image: url("assets/imgs/icons/tab-bar/active/carteirinha.svg");
      }
      &.ajuda {
        background-image: url("assets/imgs/icons/tab-bar/active/help.svg");
      }
      &.cardiologia {
        background-image: url("assets/imgs/icons/tab-bar/active/cardiology.svg");
      }
    }
  }
}

ion-tab-button:not(.tab-selected) {
  border-top: 3px solid var(--ion-color-light);
}

.sinaf-icons {
  &.tab-bar {
    background-size: 165px 68px;
    background-repeat: no-repeat;
    background-size: contain;
    &.home {
      width: 20px;
      height: 23px;
      background-image: url("assets/imgs/icons/tab-bar/home.svg");
    }
    &.carteirinha {
      width: 25px;
      height: 23px;
      background-image: url("assets/imgs/icons/tab-bar/carteirinha.svg");
    }
    &.ajuda {
      width: 23px;
      height: 23px;
      background-image: url("assets/imgs/icons/tab-bar/help.svg");
    }
    &.cardiologia {
      width: 22px;
      height: 23px;
      background-image: url("assets/imgs/icons/tab-bar/cardiology.svg");
    }
  }
}

.lower-content {
  display: flex;
  align-self: flex-end;
  flex-direction: column;
}

.enviar-receita-modal {
  --background: rgba(20, 19, 19, 0.7);

  .modal-wrapper {
    height: auto;
  }

  app-enviar-receita {
    height: 300px;
    margin: 0 20px;
    top: calc(65vh - 300px);
    background: transparent;
    position: absolute;
    border-radius: 8px;
  }
}


.logout-modal {
  --background: rgba(20, 19, 19, 0.7);

  .modal-wrapper {
    height: auto;
  }

  app-logout {
    height: 300px;
    top: calc(100vh - 300px);
    background: transparent;
    position: absolute;
    border-radius: 8px 8px 0 0;
  }
}

.app-custom-modal {
  background-color: rgba(20, 19, 19, 0.7);
}

@media (min-width: 768px) {
  .logout-modal {
    background-color: rgba(20, 19, 19, 0.7);
    app-logout {
      height: 100%;
      top: auto;
      border-radius: 8px;
    }
  }

  .enviar-receita-modal {
    background-color: rgba(20, 19, 19, 0.7);
    app-enviar-receita {
      margin: 0%;
      height: 100%;
      top: auto;
      border-radius: 8px;
    }
  }

  ion-modal::part(content) {
    height: 45%;
    width: 40%;
  }

  .app-custom-modal {
    --border-radius: 8px;
  }
}
